:root {
    --button-background-color: #009fd8;
    --button-background-inactive-color: #017292;
    --button-text-color: #ffffff;
}

* {
    margin: 0;
    padding: 0;
}

body {
    padding-bottom: 200px;
    min-width: 760px;
}

body,
p,
button,
a,
label,
.field,
select,
input[type="text"],
input[type="email"],
input[type="password"] {
    font-size: 22px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

p.bigger {
    font-size: 2rem; /* 32px */
}

p.bigger:not(first-child) {
    margin-top: 1rem; /* 16px */
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

a {
    color: black;
}

button,
.button {
    background: var(--button-background-color);
    color: white;
    padding: 14px 42px;
    border-radius: 4px;
    border: 1px solid #126d75;
    cursor: pointer;
    text-decoration: none;
}

button.bigger,
.button.bigger {
    padding: 1rem 3.5rem; /* 16px 56px */
    font-size: 2.25rem; /* 36px */
    font-weight: 500;
}

button:not(:last-child),
.button:not(:last-child) {
    margin-right: 10px;
}

button.loading:disabled .button.loading:disabled {
    background: #019bd3;
    color: white;
    border-radius: 4px;
    font-style: normal;
    border: 1px solid #126d75;
    cursor: pointer;
}

button:focus,
button:active,
.button:focus,
.button:active {
    outline: none;
}

button:disabled,
.button:disabled {
    background: #d0d0d0;
    border: 1px solid #b5b5b5;
    color: #717171;
    font-style: italic;
    cursor: default;
}

button.grey,
.button.grey {
    background: #d0d0d0;
    border: 1px solid #b5b5b5;
    color: #717171;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
    border-color: #019bd3;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    outline: none;
}

select {
    width: 100%;
    background: white;
    padding: 20px 8px;
    cursor: pointer;
    border: 1px solid #cccccc;
    border-radius: 3px;
}

.loaderButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loaderButton.wider {
    width: 200px;
}

.loaderButton.fullWidth {
    width: 100%;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db !important;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation: spin 0.6s linear infinite;
    position: absolute;
    left: 10px;
}

.loader-xl {
    border: 16px solid #fff;
    border-top: 16px solid #3498db !important;
    width: 100px;
    height: 100px;
    left: unset;
    top: 40%;
}

.loaderButton.bigger .loader {
    width: 1.5rem;
    height: 1.5rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.textAlignRight {
    text-align: right;
}

.textAlignCenter {
    text-align: center;
}

.slidingMenu {
    width: 340px;
    right: 0;
    display: none;
    position: fixed;
    height: 100%;
    top: 0;
}

.slidingMenu.open {
    display: block;
    z-index: 20;
}

.slidingMenu ul {
    list-style: none;
}

.slidingMenu li {
    padding: 31px 46px;
    letter-spacing: 1px;
}

.slidingMenu a {
    text-decoration: none;
    line-height: 32px !important;
}

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}

.topBar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.topBar a {
    line-height: 0;
}

.menuButton {
    position: absolute;
    right: 0;
    height: 63px;
    padding: 29px 34px 0 0;
    cursor: pointer;
}

.menuButton .bar {
    width: 35px;
    height: 4px;
    margin: 6px 0;
}

.secondaryAction {
    margin-top: 5rem;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottomBar {
    border-top: 1px solid #e2e2e2;
    position: fixed;
    width: calc(100% - 104px);
    bottom: 50px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 52px;
    background: #efefef;
}

.pageContent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    padding: 0 52px;
}

.whiteBox {
    padding: 30px 44px;
    background: white;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
}

.contentBox {
    width: 800px;
}

.feedbackBar {
    position: fixed;
    top: 94px;
    text-align: center;
    padding: 30px;
    z-index: 8;
    margin: 20px;
    width: calc(100% - 100px);
    border-radius: 5px;
}

.feedbackBar.success {
    background: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
    text-align: left;
}

.feedbackBar.errors {
    background: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
}

.loginForm .inner {
    padding: 34px 50px;
}

.loginForm h2,
.siteSelector h2 {
    text-align: center;
    margin-bottom: 30px;
}

select {
    display: block;
    line-height: 1.3;
    padding: 20px 24px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
        linear-gradient(to bottom, #fff 0%, #fff 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
}

select::-ms-expand {
    display: none;
}

select:hover {
    border-color: #888;
}

select:focus {
    border-color: #019bd3;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
}

select option {
    font-weight: normal;
}

.loginForm input {
    width: calc(100% - 28px);
    padding: 14px;
    margin: 5px 0 20px 0;
}

.loginForm .forgotYourPassword {
    display: flex;
    flex-direction: row-reverse;
    margin: 16px 0 30px 0;
}

.loginForm .forgotYourPasswordButton {
    cursor: pointer;
}

.siteSelectorDropdown {
    margin: 16px 0;
}

.siteSelectorDropdown input,
.siteSelectorDropdown input:focus {
    box-shadow: 0 0 0 0;
}

.radioGroup label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 18px;
}

.radioGroup label.checked {
    background-color: #e6e6e6;
}

.radioHolder {
    width: 80px;
    vertical-align: middle;
}

.radioHolder input {
    margin-top: 7px;
    margin-left: 30px;
    vertical-align: top;
}

.radioTextHolder {
    width: 80%;
}

.radioTitle {
    font-weight: bold;
}

.radioOption {
    width: 360px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 7px;
    margin-bottom: 5px;
}

.radioOption select {
    padding: 10px 40px 10px 10px;
    font-size: 18px;
}

.radioOptionText {
    width: 50%;
}

.takePhoto {
    display: flex;
    justify-content: center;
    margin: 2rem auto;
}

.video {
    border-radius: 16px;
    border: 2px solid #e6e6e6;
    background: #efefef;
    transform: scaleX(-1);
}

.videoContainer {
    position: relative;
    width: 644px;
    height: 484px;
}

.videoOverlay {
    position: absolute;
    left: 50%;
    z-index: 5;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
}

.videoOverlay--qr {
    border-radius: 0;
}

.loadingVideo {
    border-radius: 16px;
    border: 2px dashed #dedede;
    height: 484px;
    background: #efefef;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #262d3d;
}

.loadingVideo .loader {
    position: static;
    margin: 0 20px 4px 0;
    border: 4px solid #efefef;
}

.loadingSites {
    position: relative;
    text-align: center;
    margin-top: 50px;
    color: grey;
    padding-bottom: 60px;
}

.loadingSites .loader {
    top: 3px;
    left: 50%;
    margin-left: -114px;
}

.shiftNotFound {
    padding: 30px;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.workerPhoto {
    height: 200px;
    width: 200px;
    background: #d0d0d0;
    border: 1px solid #b5b5b5;
    display: inline-block;
    margin-bottom: 40px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.workerPhoto .loader {
    left: 50%;
    margin-left: -10px;
    top: 50%;
    margin-top: -10px;
    border: 4px solid #bbbbbb;
}

.workerPhoto img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.shiftBlock {
    background-color: #d1ecf1;
    border-color: #bee5eb;
    margin: 10px 0;
    padding: 15px 24px 15px 220px;
    border-radius: 5px;
    position: relative;
    min-height: 62px;
    line-height: 32px;
}

.shiftBlock.first {
    margin-top: 22px;
}

.shiftBlock.first .time {
    top: 15px;
}

.shiftBlock .time {
    width: 192px;
    text-align: right;
    position: absolute;
    left: 0;
    top: 14px;
}

.shiftBlock.green {
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.shiftBlock.reduced {
    padding: 14px 20px 14px 220px;
    min-height: auto;
}

.shiftBlock .muted {
    color: grey;
}

.shiftBlock .shift {
    display: inline-block;
}

.marginBottom10 {
    margin-bottom: 10px;
}

.marginBottom14 {
    margin-bottom: 14px;
}

.marginBottom20 {
    margin-bottom: 20px;
}

.noConnection {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.noConnection > div {
    width: 90%;
    margin: 10px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
}

.noConnection > div h2 {
    font-weight: bold;
    position: relative;
}

.noConnection > div .loader {
    position: static;
    margin: auto;
}

.systemMessages {
    display: flex;
    flex-flow: column;
    align-items: center;
    background-color: #ffff6e;
}

.systemMessage {
    padding: 10px;
}

.version {
    right: 0;
    font-size: 0.9em;
}

.modal-backdrop {
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 5;
}

.modal-container {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 90%;
    max-width: 500px;
    background: white;
    padding: 20px;
}

textarea {
    background: white;
    -webkit-appearance: none;
    border: 1px solid #999;
    width: calc(100% - 20px);
    display: block;
    margin: 14px 0;
    height: 90px;
    resize: none;
    padding: 10px;
    font-size: 20px;
}

.countdownLightsHolder {
    position: absolute;
    top: 20px;
    right: 20px;
}

.countdownLight {
    display: inline-block;
    height: 30px;
    width: 30px;
    margin-left: 10px;
    border-radius: 30px;
}

.countdownRedirectBox {
    color: #262d3d;
}

.devDebugBtn {
    padding: 5px 10px;
    font-size: 14px;
    height: 29px;
    vertical-align: middle;
    margin-top: -3px;
    margin-left: 10px;
}

.footer {
    overflow: hidden;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1;
    height: 50px;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    background-color: #f3f5f6;
    color: #232323;
}

.footer .footerLeft {
    padding: 10px;
    flex: 50%;
}

.footer .footerRight {
    padding: 10px;
    flex: 50%;
    font-size: 12px;
}

.poweredBy {
    display: inline-block;
}

.poweredBy a {
    margin-right: 20px;
}

.poweredBy img {
    vertical-align: middle;
    width: 70px;
    height: 17px;
}

.loginWrapper .welcomeMessage {
    margin-bottom: 30px;
    text-align: center;
}

.loginWrapper .fieldset {
    padding: 16px 45px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;
}

.loginWrapper h1 {
    font-weight: 300;
}

.loginWrapper .alertsWrapper {
    margin: 12px 0 16px;
    padding: 12px 10px;
    background-color: #f1f9ff;
    border: 1px solid #bacad7;
    color: #7990a3;
    border-radius: 4px;
}

.loginWrapper .buttonsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginWrapper .buttonsWrapper img {
    margin-right: 16px;
    width: 35px;
}

.loginWrapper .buttonsWrapper img.Staffline {
    width: 55px;
}

.loginWrapper .buttonsWrapper button {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    padding: 16px 12px;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #ccc;
    color: #8c8c8c;
}

.loginButtonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.timezoneAbbreviation {
    text-decoration: underline;
    text-decoration-style: dotted;
    padding-left: 0.25rem;
}
